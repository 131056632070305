import Link from "next/link";
import Image from "next/image";
import React from "react";

const decodeHtmlEntities = (input) => {
	const parser = new DOMParser();
	const decodedString = parser.parseFromString(
		`<!doctype html><body>${input}`,
		"text/html"
	).body.textContent;
	return decodedString;
};

const truncateText = (text, maxLength) => {
	if (text.length <= maxLength) return text;
	return text.slice(0, maxLength) + "...";
};

function FeatureCard({ title, text, image, link }) {
	return (
		<div className="flex flex-col w-full lg:w-full xl:w-auto mb-4 md:mb-0">
			<div className="w-[315px] lg:w-[275px] xl:w-[315px] h-[490px] lg:h-[490px] xl:h-[490px] bg-gray-200 rounded-xl shadow-lg pointer-events-none card relative">
				<div className="w-[100%] mx-auto h-[240px] relative">
					<Image
						src={image}
						alt={title}
						fill
						quality={100}
						className="rounded-t-xl object-fit"
					/>
				</div>
				<div className="flex flex-col justify-between h-[240px]">
					<div>
						<p className="mx-2 text-sm font-bold text-black mt-4">
							{decodeHtmlEntities(title)}
						</p>
						<p
							dangerouslySetInnerHTML={{ __html: truncateText(text, 91) }}
							className="mx-2 mt-2 text-[gray] font-medium text-sm truncate-text"
						></p>
					</div>
					<div className="absolute bottom-6 left-6 bg-[#053c61] hover:bg-[#053c61be] transition-all ease-in-out duration-300 px-4 py-2 z-10 text-white rounded-[10px] cursor-pointer">
						<button
							sx={{ borderRadius: "10px", textDecoration: "none" }}
							variant="contained"
							aria-label={`Read more about ${title}`}
						>
							Read More...
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeatureCard;
