"use client";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import isoCertifiedLogo from "@/public/svg/isoCertifiedLogo.svg";
import iso from "@/public/svg/iso.svg";
import hipaa from "@/public/svg/hipaa.svg";
import hyperledger from "@/public/svg/hyperledger.svg";
import aiml from "@/public/svg/aiml.svg";
import amb from "@/public/svg/amb.svg";
import blockchain from "@/public/svg/blockchain.svg";
import dataprivacy from "@/public/svg/dataprivacy.svg";
import iotEco from "@/public/svg/iotEco.svg";
import fhir from "@/public/svg/fhir.svg";
import iim from "@/public/svg/iim.svg";
import istart from "@/public/svg/istart.svg";
import drishti from "@/public/svg/drishti.svg";
import iima from "@/public/svg/iima.svg";
import vanasthali from "@/public/svg/vanasthali.svg";
import iitr from "@/public/svg/iitr.svg";
import bitsp from "@/public/svg/bitsp.svg";
import { Skeleton, useMediaQuery } from "@mui/material";

const items = [
	{ name: isoCertifiedLogo, id: 1 },
	{ name: iso, id: 2 },
	{ name: hipaa, id: 3 },
	{ name: hyperledger, id: 4 },
	{ name: amb, id: 5 },
	{ name: blockchain, id: 6 },
	{ name: dataprivacy, id: 7 },
	{ name: iotEco, id: 8 },
	{ name: fhir, id: 9 },
	{ name: aiml, id: 10 },
];

const supportEcosystemItems = [
	iim,
	vanasthali,
	iitr,
	bitsp,
	drishti,
	istart,
	iima,
];

const Companies = () => {
	const gridColumns = 3;
	const isMobile = useMediaQuery("(min-width: 400px)");
	const isSmallScreen = useMediaQuery("(max-width: 663px)");
	const isTablet = useMediaQuery("(min-width: 1024px)");
	const isLessThan1280 = useMediaQuery("(min-width:1280px)");
	return (
		<Box
			// width={"100%"}
			display="flex"
			// flexDirection={isSmallScreen ? "column" : "row"}
			gap={6}
			// mt={isTablet ? 2 : 10}
			// ml={isTablet && !isSmallScreen && 2.5}
			sx={{
				flexDirection: { xs: "column", sm: "row" },
				marginTop: { xs: "40px", sm: "16px" },
				width: "100%",
				padding: "0px 40px",
				justifyContent: "center",
			}}
			// alignItems={isSmallScreen ? "center" : ""}
		>
			<Box
				sx={{
					border: "1px solid #72C1D5",
					borderRadius: 2,
					// maxWidth: "600px",
					padding: 2,
					position: "relative",
					width: { xs: "100%", sm: "400px" },
				}}
				// width={isSmallScreen ? "80%" : "60%"}
			>
				<Box
					sx={{
						width: "80%",
						background: "#E4F9FF",
						paddingX: 2,
						paddingY: 1,
						borderRadius: "30px",
						boxShadow: 1,
						position: "absolute",
						top: -20,
						left: "50%",
						transform: "translateX(-50%)",
					}}
				>
					<Typography
						// variant="h3"
						color="#053C61"
						textAlign="center"
						className="text-[14px] md:text-[20px] 2xl:text-[24px] font-[700]"
					>
						Our Compliances
					</Typography>
				</Box>
				{items?.length === 0 ? (
					<Box
						display="grid"
						gridTemplateColumns={`repeat(${gridColumns}, 1fr)`}
						gap={3}
						mt={isTablet ? 6 : 4}
						overflow={"hidden"}
					>
						{[...Array(9)].map((_, index) => (
							<Skeleton
								key={index}
								animation="wave"
								sx={{ borderRadius: "50%", height: "100px", width: "60px" }}
							/>
						))}
					</Box>
				) : (
					<Box
						display="grid"
						gridTemplateColumns={`repeat(${gridColumns}, 1fr)`}
						gap={3}
						mt={isTablet ? 6 : 4}
						overflow={"hidden"}
						// className="min-h-[440px]"
					>
						{items?.map((item, index) => (
							<div key={item.id}>
								{item.name === aiml && <Box></Box>}
								<Image
									src={item.name}
									alt={`Compliance ${index + 1}`}
									width={item === aiml ? 150 : 100}
									height={item === aiml ? 100 : 100}
								/>
							</div>
						))}
					</Box>
				)}
			</Box>

			<Box
				sx={{
					border: "1px solid #72C1D5",
					borderRadius: 2,
					// maxWidth: "600px",
					padding: 2,
					position: "relative",
					width: { xs: "100%", sm: "400px" },
					// width={isSmallScreen ? "80%" : "60%"}
				}}
			>
				<Box
					sx={{
						width: "80%",
						background: "#E4F9FF",
						paddingX: 2,
						paddingY: 1,
						borderRadius: "30px",
						boxShadow: 1,
						position: "absolute",
						top: -20,
						left: "50%",
						transform: "translateX(-50%)",
					}}
				>
					<Typography
						// variant="h3"
						color="#053C61"
						textAlign="center"
						className="text-[14px] md:text-[20px] 2xl:text-[24px] font-bold"
					>
						Support Ecosystem
					</Typography>
				</Box>

				{items?.length === 0 ? (
					<Box
						display="grid"
						gridTemplateColumns={`repeat(${gridColumns}, 1fr)`}
						gap={3}
						mt={isSmallScreen ? 3 : isTablet ? 6 : 6}
					>
						{[...Array(9)].map((_, index) => (
							<Skeleton
								key={index}
								animation="wave"
								sx={{ borderRadius: "50%", height: "100px", width: "60px" }}
							/>
						))}
					</Box>
				) : (
					<Box
						display="grid"
						gridTemplateColumns={`repeat(${gridColumns}, 1fr)`}
						gap={3}
						mt={isSmallScreen ? 3 : isTablet ? 6 : 6}
						// className="min-h-[440px]"
					>
						{supportEcosystemItems?.map((item, index) => (
							<Image
								key={index}
								src={item}
								alt={`Support Ecosystem ${index + 1}`}
								width={100}
								height={100}
							/>
						))}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default Companies;
