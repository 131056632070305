"use client";
import React, { useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import {
	ArrowBackIosNew as ArrowBackIosNewIcon,
	ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import CustomCard from "./CustomCard";
import feature2 from "@/public/svg/abha_integration.svg";
import feature1 from "@/public/svg/chikitsa-macbook.svg";
import feature3 from "@/public/svg/blockchain2.svg";
import feature4 from "@/public/svg/cuate-cropped.svg";

const FeatureComponent = ({ height }) => {
	const [activeIndex, setActiveIndex] = useState(0);

	const matchesSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const matchesMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
	const matchesLg = useMediaQuery("(max-width:1024px)");

	const features = [
		{
			id: 1,
			title: "ABHA Integration",
			description:
				"Creating a centralized digital health ID by integrating ABHA (Ayushman Bharat Health Account), where patients all data can be in one place.",
			features: [
				"CREATING ABHA ID",
				"PATIENTS CONSENT",
				"HEALTH RECORD ACCESS",
			],
			image: feature2,
			alt: "ABHA Integration illustration",
		},
		{
			id: 2,
			title: "AI Based Hospitals & Health Management Analytics",
			description:
				"We provide a holistic dashboard of health management analytics to reduce the error rate in healthcare facilities. Here are a few health metrics that we measure.",
			features: [
				"REALTIME PATIENT'S ACTIVITY",
				"HEALTH FORECASTING",
				"CHRONIC DISEASES MONITORING",
			],
			image: feature1,
			alt: "AI based health management illustration",
		},
		{
			id: 3,
			title: "Blockchain to store patients data securely",
			description:
				"In our Chikitsa healthcare ecosystem, we leverage blockchain technology to ensure the secure storage of patient data and medical records.",
			features: [],
			image: feature3,
			alt: "Blockchain for secure data storage illustration",
		},
		{
			id: 4,
			title: "One Time Registration Process",
			description:
				"Once you complete the registration process on our portal, you will be fully onboarded. Subsequently, you will not be required to register again in order to visit the hospital.",
			features: [],
			image: feature4,
			alt: "One-time registration process illustration",
		},
	];

	const handlePrevClick = () => {
		setActiveIndex((prevIndex) =>
			prevIndex === 0 ? features.length - 1 : prevIndex - 1
		);
	};

	const handleNextClick = () => {
		setActiveIndex((prevIndex) =>
			prevIndex === features.length - 1 ? 0 : prevIndex + 1
		);
	};

	return (
		<Box
			display="flex"
			flexDirection={{ xs: "column", md: "row" }}
			alignItems="center"
			justifyContent="center"
			gap={matchesMd && 2}
			padding={2}
			// height={matchesSm ? "100vh" : matches ? "100vh" : height}
			marginTop={{ xs: 2, lg: 8, md: 2 }}
		>
			{/* Button */}
			{!matchesSm && (
				<Button
					sx={{
						width: { xs: "40px", md: "60px" },
						height: { xs: "40px", md: "60px" },
						color: "#72C1D5",
						fontSize: { xs: "24px", md: "36px" },
						fontWeight: "bold",
					}}
					onClick={handlePrevClick}
					aria-label="Previous feature"
				>
					<ArrowBackIosNewIcon
						sx={{
							fontSize: { xs: "24px", md: "36px" },
							fontWeight: "bold",
						}}
					/>
				</Button>
			)}

			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				sx={{
					width: { xs: "100%", sm: "90%" },
				}}
				position="relative"
				overflow="hidden"
				height={{ xs: "580px", sm: "600px", md: "600px", lg: "700px" }}
			>
				{features?.map((feature, index) => (
					<CustomCard
						key={feature.id}
						index={index}
						activeIndex={activeIndex}
						feature={feature}
						features={features}
					/>
				))}
			</Box>
			{matchesSm && (
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Button
						sx={{
							width: { xs: "40px", md: "60px" },
							height: { xs: "40px", md: "60px" },
							color: "#72C1D5",
							fontSize: { xs: "24px", md: "36px" },
							fontWeight: "bold",
						}}
						onClick={handlePrevClick}
						aria-label="Previous feature"
					>
						<ArrowBackIosNewIcon
							sx={{
								fontSize: { xs: "24px", md: "36px" },
								fontWeight: "bold",
							}}
						/>
					</Button>
					<Button
						sx={{
							width: { xs: "40px", md: "60px" },
							height: { xs: "40px", md: "60px" },
							color: "#72C1D5",
							fontSize: { xs: "24px", md: "36px" },
							fontWeight: "bold",
						}}
						onClick={handleNextClick}
						aria-label="Next feature"
					>
						<ArrowForwardIosIcon
							sx={{
								fontSize: { xs: "24px", md: "36px" },
								fontWeight: "bold",
							}}
						/>
					</Button>
				</Box>
			)}
			{!matchesSm && (
				<Button
					sx={{
						width: { xs: "40px", md: "60px" },
						height: { xs: "40px", md: "60px" },
						color: "#72C1D5",
						fontSize: { xs: "24px", md: "36px" },
						fontWeight: "bold",
					}}
					onClick={handleNextClick}
					aria-label="Next feature"
				>
					<ArrowForwardIosIcon
						sx={{
							fontSize: { xs: "24px", md: "36px" },
							fontWeight: "bold",
						}}
					/>
				</Button>
			)}
		</Box>
	);
};

export default FeatureComponent;
