"use client";
import { useTheme } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import Head from "next/head";
import Image from "next/image";

export default function About() {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<>
			<Head>
				<link rel="preload" href="/svg/chikitsa-about-us.svg" as="image" />
				<link rel="preload" href="/svg/chikitsa-about-effect.svg" as="image" />
			</Head>
			<section
				id="about"
				className="pt-8 xl:pt-[74px] px-4 sm:px-[40px] overflow-hidden xl:px-[200px] w-[100%]"
			>
				<div className="flex flex-col md:flex-row-reverse justify-around overflow-x-visible bg-cover relative z-20">
					{/* <div className="flex flex-col md:flex-row-reverse justify-around overflow-x-visible bg-[url('/img/aboutBG.png')] bg-cover relative z-20"> */}
					<Box
						sx={{
							background: "#E4F9FF",
							position: "absolute",
							top: { md: "160px", lg: "60px" },
							width: "100%",
							maxWidth: "460px",
							height: { md: "280px", lg: "280px" },
							borderRadius: "239.5px 0 0 239.5px",
							overflow: "hidden",
							opacity: "30%",
							transform: "scale(1)",
							zIndex: -1,
						}}
					>
						<Image
							src="/svg/chikitsa-about-effect.svg"
							alt="chikitsa.io"
							height={100}
							width={100}
						/>
					</Box>
					<div className="flex justify-center lg:w-[400px] w-full">
						<Image
							src="/svg/chikitsa-about-us.svg"
							width={400}
							height={380}
							alt="chikitsa.io"
						/>
					</div>
					<div className="flex flex-col justify-center p-4 md:p-8 lg:w-[650px] md:w-[90vw]">
						{!isSmallScreen && (
							<Box
								sx={{
									background: "#E4F9FF",
									position: "absolute",
									top: "-100px",
									left: "-200px",
									width: "100%",
									maxWidth: "310px",
									height: "280px",
									borderRadius: "239.5px 0 0 239.5px",
									overflow: "hidden",
									opacity: "30%",
									transform: "scale(1)",
									rotate: "-180deg",
									zIndex: -1,
								}}
							>
								<Image
									src="/svg/chikitsa-about-effect.svg"
									alt="Image 1"
									height={100}
									width={100}
									layout="responsive"
									objectFit="cover"
								/>
							</Box>
						)}
						<p className="heading leading-[28px] sm:leading-[35px] text-[20px] sm:text-[30px] ">
							Smart Cloud Driven HMIS Solution
						</p>
						<p className="text-black text-[14px] sm:text-[16px] leading-[25px] sm:leading-[30px] text-justify">
							Chikitsa is a transformative healthcare solution utilizing IoT,
							AI, ML, and Blockchain. It offers a seamless, integrated SDK that
							automates and optimizes outpatient workflows in hospitals.
							Providing a unique patient identity, medical report analysis, and
							end-to-end digitalization, Chikitsa enhances efficiency. Its
							real-time analytics empower hospitals to make informed decisions.
							Chikitsa is on a mission to revolutionize healthcare delivery.
						</p>
					</div>
				</div>
			</section>
		</>
	);
}
