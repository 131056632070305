"use client";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useMemo } from "react";
import AOSS from "./AOS";
import "react-multi-carousel/lib/styles.css";

// Dynamically import Carousel (to reduce bundle size)
const Carousel = dynamic(() => import("react-multi-carousel"), {
	ssr: false, // Disable SSR for this component
});

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 3,
	},
};

// Client data
const clientsData = [
	{ id: 0, src: "/svg/logo_lifeplus.svg", alt: "Life Plus Logo" },
	{ id: 1, src: "/svg/maternite.svg", alt: "Maternite Logo" },
	{ id: 2, src: "/svg/go_medicx.svg", alt: "Go Medicx Logo" },
	{ id: 3, src: "/svg/trisense_logo.svg", alt: "Trisense Logo" },
	{ id: 4, src: "/svg/GOI.svg", alt: "GOI Logo" },
	{ id: 5, src: "/svg/mother.svg", alt: "Mother Logo" },
	{ id: 6, src: "/svg/emerge.svg", alt: "Emerge Logo" },
];

const Clients = () => {
	// Memoize the clients array to prevent unnecessary re-renders
	const clients = useMemo(() => clientsData, []);

	return (
		<section
			id="clients"
			className="w-[100%] pb-6 sm:py-24 mt-16 sm:mt-24 lg:mt-10 md:pt-20 lg:pt-20 lg:pb-10"
		>
			<div className="flex flex-col justify-center items-center w-full md:gap-0">
				<h2 className="heading text-[14px] sm:text-[24px]">
					Our <span className="text-[#1FBBE2]">Clients</span>
				</h2>
				<div className="w-[90%]">
					<AOSS />
				</div>
			</div>
			<Carousel
				swipeable={true}
				draggable={true}
				responsive={responsive}
				ssr={true}
				infinite={true}
				autoPlay={true}
				autoPlaySpeed={3000}
				keyBoardControl={true}
				customTransition="all 1s ease-in-out"
				transitionDuration={1000}
				removeArrowOnDeviceType={["tablet", "mobile"]}
				dotListClass="custom-dot-list-style"
				itemClass="flex justify-center items-center p-4"
			>
				{clients?.map((client, index) => (
					<div
						key={client.id}
						className={`relative w-40 h-40 md:w-60 md:h-60 bg-white ${
							index === 5 ? "h-32 md:h-48" : "h-40 md:h-60"
						}`}
						style={{ aspectRatio: "16/9" }}
					>
						{/* Lazy load images to improve performance */}
						<Image
							src={client.src}
							alt={client.alt}
							layout="fill"
							objectFit="contain"
							className="rounded-md"
							style={{ mixBlendMode: "multiply" }}
							priority={index === 0} // Load the first image immediately
						/>
					</div>
				))}
			</Carousel>
		</section>
	);
};

export default Clients;
