import axios from "axios";
const URL = process.env.NEXT_PUBLIC_APP_BASE_URL;
const CONTACT_URL = process.env.NEXT_PUBLIC_APP_BASE_URL_CONTACT;
const CAREER_URL = process.env.NEXT_PUBLIC_APP_BASE_URL_CAREER;

export const getBlog = async () => {
	const BlogURL = `${URL}/posts`;
	const { data } = await axios.get(BlogURL);
	return data;
};

export const contactForm = async (formData) => {
	const URL = `${CONTACT_URL}/submitform`;
	try {
		const { data } = await axios.post(URL, {
			name: formData?.name,
			phoneNumber: formData?.phone,
			email: formData?.email,
			message: formData?.message,
			environmentName: "Production",
		});
		return data;
	} catch (error) {
		return error;
	}
};

export const careerForm = async (formData) => {
	const URL = `${CAREER_URL}/careers/uploadResume`;
	try {
		const { data } = await axios.post(URL, {
			firstName: formData.firstName,
			lastName: formData.lastName,
			phone: formData.phone,
			email: formData.email,
			position: formData.position,
			skills: formData.skills,
			resume: formData.resume,
			// environmentName: "Production",
		});
		return data;
	} catch (error) {
		return error;
	}
};
