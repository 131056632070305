"use client";
import { useEffect, useState } from "react";
import FeatureCard from "./FeatureCard";
import { getBlog } from "@/app/api/routes";
import Link from "next/link";
import { Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useRouter } from "next/navigation";
import { useSwipeable } from "react-swipeable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "../AOS";

function Feature5() {
	const [blogData, setBlogData] = useState([]);
	const [currentSet, setCurrentSet] = useState(0);

	const router = useRouter();

	useEffect(() => {
		const fetchData = async () => {
			const res = await getBlog();
			setBlogData(res);
		};
		fetchData();
	}, []);

	const imageArr = blogData?.map((item) => ({
		title: item.title.rendered || "--",
		text: item.content.rendered.slice(0, 101),
		image: item.yoast_head_json?.og_image?.[0]?.url,
		link: item.link || "#",
	}));

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.only("xs"));

	const blogsPerSet = 4;
	const totalSets = Math.ceil(imageArr.length / blogsPerSet);

	const handleDotClick = (index) => {
		setCurrentSet(index);
	};

	const handleAccordionClick = (item) => {
		router.push(`${item}`);
	};

	const handleSwipedLeft = () => {
		setCurrentSet((prevSet) => (prevSet + 1) % totalSets);
	};

	const handleSwipedRight = () => {
		setCurrentSet((prevSet) => (prevSet - 1 + totalSets) % totalSets);
	};

	const handlers = useSwipeable({
		onSwipedLeft: handleSwipedLeft,
		onSwipedRight: handleSwipedRight,
		trackMouse: true,
	});

	return (
		<section id="blogs" className="flex flex-col justify-center items-center">
			<div className="flex flex-col justify-center items-center w-[80%] md:gap-0">
				<h2 className="heading text-[14px] sm:text-[24px] ">
					Explore <span className="text-[#1FBBE2]">Blogs</span>
				</h2>

				<AOS />
			</div>
			{imageArr.length === 0 ? (
				<div className="h-[600px] w-full flex items-center flex-col ">
					{[...Array(10)].map((_, index) => (
						<Skeleton
							key={index}
							height={"400px"}
							animation="wave"
							width={"80%"}
						/>
					))}
				</div>
			) : (
				<div
					{...handlers}
					className="flex flex-col md:flex-row md:space-x-4 overflow-x-auto scrollbar-hide mt-10 max-w-[85vw]"
				>
					{!matches ? (
						imageArr?.map((item, index) => {
							return (
								<Link href={item.link} key={index}>
									<FeatureCard
										text={item.text}
										image={item.image}
										title={item.title}
										link={item.link}
									/>
								</Link>
							);
						})
					) : (
						<div className="accordion">
							{imageArr?.map((item, index) => {
								return (
									<div key={index} className="accordion-item">
										<div
											className="accordion-header"
											onClick={() => handleAccordionClick(item.link)}
											aria-label={`Expand to read about "${item.title}". ${item.text}`}
										>
											{item.title}
											<span className="accordion-icon">
												<ExpandMoreIcon style={{ rotate: "-90deg" }} />
											</span>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			)}
		</section>
	);
}

export default Feature5;
