"use client";
import Head from "next/head";
import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function How() {
	const [isVideoPlaying, setIsVideoPlaying] = useState(false);
	const videoRef = useRef(null);
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<section
			id="our-demo"
			className=" h-[80%] md:h-[80%h] lg:h-[90vh] bg-[#F9FCFB] flex justify-center items-center flex-col bg-[url('/svg/demo_video.svg')] bg-cover bg-center md:mt-4 lg:mt-14"
		>
			<div className="flex flex-col items-center lg:mt-10 px-4 lg:px-12 gap-6  mx-auto">
				<h2 className="text-[#053c61] font-bold text-[24px] md:heading text-center uppercase">
					our demo <span className="text-[#1FBBE2]">video</span>
				</h2>
				<p className="paragraph !font-[400] text-center w-[90%] md:w-[70%]">
					This is our product demo where we explain the process and steps that
					include the end-to-end hospital journey of a patient, showcasing the
					way we solve healthcare challenges.
				</p>
				<div
					className="relative w-full md:w-[80%] lg:w-[50%] xl:w[50%]"
					data-aos="zoom-in"
				>
					<video
						ref={videoRef}
						width="100%"
						height="80%"
						controls
						className="rounded-xl"
						poster="/img/chikitsa hcx.jpg"
						onPlay={() => setIsVideoPlaying(true)}
						onPause={() => setIsVideoPlaying(false)}
					>
						<source src="/img/CHCX.mp4" type="video/mp4" />
						<p>Your browser does not support the video tag.</p>
					</video>
					{/* {!isVideoPlaying && (
              <div className="absolute inset-0 flex items-center justify-center">
                <button
                  className="bg-black bg-opacity-50 text-white rounded-full p-3 transition duration-300 hover:bg-opacity-75"
                  onClick={() => {
                    setIsVideoPlaying(true);
                    videoRef.current.play();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path fillRule="evenodd" d="M8 5v10l7-5-7-5z" />
                  </svg>
                </button>
              </div>
            )} */}
				</div>
			</div>
		</section>
	);
}

export default How;
