import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Image from "next/image";
import checkbox from "@/public/svg/check2.svg";

const CustomCard = ({ index, activeIndex, feature, features }) => {
	const matchesSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const [firstWord, ...rest] = feature.title.split(" ");

	const isActive = activeIndex === index;
	const isPrev =
		activeIndex === index + 1 ||
		(activeIndex === 0 && index === features.length - 1);
	const isNext =
		activeIndex === index - 1 ||
		(activeIndex === features.length - 1 && index === 0);

	return (
		<Box
			className={`absolute transition-all duration-500 
        ${
					isActive
						? "z-10 scale-100 opacity-100 flex flex-col items-center"
						: isPrev || isNext
						? "z-0 scale-80 opacity-60 flex flex-col items-center"
						: "z-0 scale-80 opacity-60 flex flex-col items-center"
				}`}
			sx={{
				display: !isActive && !isPrev && !isNext && "none",
				transform: isPrev
					? "translateX(-130%)"
					: isNext
					? "translateX(130%)"
					: "",
				border: isActive ? "2px solid #1FBBE2" : "1px solid gray",
				scale: isActive ? "1.1" : "0.8",
				width: { xs: "80vw", sm: "50vw", lg: "400px" },
				// height: { xs: "480px", lg: "550px" },
				paddingY: "10px",
				borderRadius: "16px",
				overflow: "hidden",
				backgroundColor: "white",
			}}
		>
			<Box
				className="relative flex justify-center items-center"
				sx={{
					width: { xs: "150px", md: "200px" },
					height: { xs: "170px", md: "200px" },
					position: "relative",
				}}
			>
				<Image
					src={feature.image}
					alt={feature.alt || feature.title}
					height={100}
					sx={{
						objectPosition: "center center",
					}}
					className="rounded-t-xl mt-4 w-[150px] sm:w-[175px]"
				/>
			</Box>
			<Box
				sx={{
					padding: { xs: "8px", sm: "12px" },
				}}
				display="flex"
				flexDirection="column"
				// justifyContent="space-between"
				height="calc(100% - 200px)"
			>
				<Box textAlign="center">
					<Typography
						variant="h3"
						fontWeight="bold"
						sx={{
							fontSize: {
								xs: "20px",
								md: "24px",
								lg: "29px",
							},
							marginTop: "10px",
							textTransform: "uppercase",
							fontWeight: "700",
						}}
					>
						<span style={{ color: "#1FBBE2" }}>{firstWord}</span>{" "}
						<span style={{ color: "#053C61" }}>{rest.join(" ")}</span>
					</Typography>
					<Typography
						className="m-0.2 sm:m-2 text-[14px] sm:text-[14px] text-black "
						dangerouslySetInnerHTML={{ __html: feature.description }}
					/>
				</Box>
				<Box className=" flex justify-center items-center">
					{feature?.features?.length > 0 && (
						<Box mt={2}>
							{feature?.features?.map((feat, idx) => (
								<Box key={idx} display="flex" alignItems="center" mb={1}>
									<Image
										src={checkbox}
										alt="Checkmark"
										width={16}
										height={16}
									/>
									<Typography
										className="text-[14px] sm:text-[14px] font-[600] "
										color="textPrimary"
										sx={{
											color: "#053C61",
											fontWeight: "bold",
											marginLeft: "10px",
										}}
									>
										{feat}
									</Typography>
								</Box>
							))}
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default CustomCard;
