"use client";
import Aos from "aos";
import React, { useEffect } from "react";
const AOS = () => {
	useEffect(() => {
		Aos.init();
	}, []);

	return (
		<div
			className="flex justify-evenly w-full items-center gap-4 h-0  "
			data-aos="zoom-in"
		>
			<hr className="block w-full border-t-2 border-gray " />
			<div className="flex gap-2">
				<div className="w-2.5 h-2.5 bg-gray-400 rounded-full"></div>
				<div className="w-2.5 h-2.5 bg-gray-400 rounded-full"></div>
				<div className="w-2.5 h-2.5 bg-gray-400 rounded-full"></div>
			</div>
			<hr className="block w-full border-t-2 border-gray" />
		</div>
	);
};

export default AOS;
